import pptxgen from "pptxgenjs";
// Had to add parsley to the JS files seperately.
// Because they were called like this "form.parsley().validate()" and this fails with parsley is not a function.
import "parsleyjs/dist/parsley.min.js";

 function downloadSuccessfull(successEl, spinnerEl) {
    // Show the success message and hide it after 5 seconds.
    if (successEl) {
        successEl.show();
        setTimeout(function () {
            successEl.hide();
        }, 5000);
    }
    // Hide the spinner
    if (spinnerEl) {
        spinnerEl.hide();
    }
    // Enable buttons
    $("button.export").prop('disabled', false);
    $("button.export").removeClass('disabled');
}

 function downloadError(dangerEl, spinnerEl) {
    if (dangerEl) {
        dangerEl.show();
        setTimeout(function () {
            dangerEl.hide();
        }, 5000);
    }
    // Hide the spinner
    if (spinnerEl) {
        spinnerEl.hide();
    }
    // Enable buttons
    $("button.export").prop('disabled', false);
    $("button.export").removeClass('disabled');
}

var defaultPptxTitle = "ADD THE NAME OF YOUR OFFERING HERE";
var defaultPptxDescription = "Add a short description of your offering here. Maximum length is 500 characters.";
var fontTahoma = "Tahoma";
var masterSlide1Name = "PLACEHOLDER_SLIDE1";
var titleCompanies = "Companies"
var sectionTitleIntroduction = "Introduction";
var placeholderCompanyTitle = "company_title";
var placeholderCompanyTitleIntro = "company_title_intro";

function renderPptxSlide1(pptx, data) {
    var slide1 = pptx.addSlide({ masterName: masterSlide1Name, sectionTitle: sectionTitleIntroduction });
    slide1.addText(data && data.Title ? data.Title : defaultPptxTitle, { placeholder: placeholderCompanyTitle });
    slide1.addText(data && data.Description ? data.Description : defaultPptxDescription, { placeholder: placeholderCompanyTitleIntro });
}

function renderPptxSlide2(pptx, companies) {
    var subofferingTitlePpt = "";
    $.each(companies, function (index) {
        if (this.SubOfferingTitle && this.SubOfferingTitle !== subofferingTitlePpt) {
            var slideSubOffering = pptx.addSlide({ masterName: masterSlide1Name, sectionTitle: titleCompanies });
            slideSubOffering.addText(this.SubOfferingTitle, { placeholder: placeholderCompanyTitle });
            slideSubOffering.addText(this.SubOfferingDescription, { placeholder: placeholderCompanyTitleIntro });
            subofferingTitlePpt = this.SubOfferingTitle;
        }

        var slide2 = pptx.addSlide({ sectionTitle: titleCompanies });

        if (this.Logo) {
            var logoHeight = 0.5;
            var logoWidth = 0.0;
            var aspectRatio = this.Logo.ImageAspectRatio;
            logoWidth = logoHeight * aspectRatio;
            slide2.addImage({ data: this.Logo.ImageBase64, x: 0.4, y: 0.2, h: logoHeight, w: logoWidth });
        }
        
        slide2.addText(
            [
                { text: this.Name, options: { bold: true, color: "#002da1", breakLine: true, fontSize: 16 } },
                { text: "\n" + this.ShortIntroduction, options: { fontFace: fontTahoma } },
            ],
            { x: 0.2, y: 0.8, w: 8, h: 2.2, fill: "#edf6fe", fontFace: fontTahoma, fontSize: 12, align: "left", valign: "top", inset: 0.2 }
        );
        slide2.addText(
            [
                { text: "COMPANY SIZE", options: { bold: true, color: "#002da1", breakLine: true } },
                { text: "\n" + this.CompanySize, options: { fontFace: fontTahoma } },
            ],
            { x: 0.25, y: 3, w: 3.2, h: 0.8, fontFace: fontTahoma, fontSize: 12, align: "left", valign: "top" }
        );
        slide2.addText(
            [
                { text: "TURNOVER", options: { bold: true, color: "#002da1", breakLine: true } },
                { text: "\n" + this.CompanyTurnover, options: { fontFace: fontTahoma } },
            ],
            { x: 4.5, y: 3, w: 4.2, h: 0.8, fontFace: fontTahoma, fontSize: 12, align: "left", valign: "top" }
        );
        if (this.MarketingImage) {
            var marketingImageHeight = 2.7;
            var marketingImageWidth = 0.0;
            var aspectRatio2 = this.MarketingImage.ImageAspectRatio;
            marketingImageWidth = marketingImageHeight * aspectRatio2;
            slide2.addImage({ data: this.MarketingImage.ImageBase64, x: 8.3, y: 0.8, h: marketingImageHeight, w: marketingImageWidth, sizing: { type: "contain", h: marketingImageHeight, w: 4.7 } });
        }

        slide2.addText(
            [
                { text: "VALUE PROPOSITION", options: { bold: true, color: "#002da1", breakLine: true } },
                { text: "\n" + this.Proposition, options: { fontFace: fontTahoma } },
            ],
            { x: 0.25, y: 4.0, w: 4.2, h: 2.7, fontFace: fontTahoma, fontSize: 12, align: "left", valign: "top" }
        );

        var partners = (this.Partners && this.Partners !== null && this.Partners.length > 0) ? this.Partners : "";
        slide2.addText(
            [
                { text: "PREFERRED PARTNERS", options: { bold: true, color: "#002da1", breakLine: true } },
                { text: "\n" + partners, options: { fontFace: fontTahoma } },
            ],
            { x: 4.5, y: 4.0, w: 5.5, h: 2.7, fontFace: fontTahoma, fontSize: 12, align: "left", valign: "top" }
        );

        var address1 = (this.CompanyAddress && this.CompanyAddress !== null && this.CompanyAddress.length > 0) ? this.CompanyAddress : "";
        var address2 = (this.CompanyAddress2 && this.CompanyAddress2 !== null && this.CompanyAddress2.length > 0) ? this.CompanyAddress2 : "";
        var companyWebsiteData = this.CompanyWebsite && this.CompanyWebsite !== "" ? {
            text: "\n" + this.CompanyWebsite, options: {
                hyperlink: {
                    url: this.CompanyWebsite,
                    underline: true
                }, color: "#002da1", fontFace: fontTahoma, breakLine: true
            }
        } : { text: "", options: { color: "#002da1", fontFace: fontTahoma, breakLine: true } };
        var title = (this.PersonTitle && this.PersonTitle !== null && this.PersonTitle.length > 0) ? this.PersonTitle.toUpperCase() : "";
        var person = (this.PersonName && this.PersonName !== null && this.PersonName.length > 0) ? this.PersonName.toUpperCase() : "";
        var phone = (this.PersonPhone && this.PersonPhone !== null && this.PersonPhone.length > 0) ? this.PersonPhone : "";
        var email = (this.PersonEmail && this.PersonEmail !== null && this.PersonEmail.length > 0) ? this.PersonEmail : "";
        slide2.addText(
            [
                { text: "CONTACT INFO", options: { color: "#002da1", fontFace: fontTahoma, breakLine: true } },
                companyWebsiteData,
                { text: "\n\n" + address1, options: { fontFace: fontTahoma, fontSize: 10 } },
                { text: "\n" + address2, options: { fontFace: fontTahoma, fontSize: 10 } },
                { text: "\n\n" + title.toUpperCase(), options: { fontFace: fontTahoma, fontSize: 10 } },
                { text: "\n" + person.toUpperCase(), options: { color: "#002da1", fontSize: 10, fontFace: fontTahoma, bold: true, lineSpacing: 13 } },
                { text: "\n" + phone, options: { color: "#002da1", fontSize: 10, fontFace: fontTahoma } },
                { text: "\n" + email, options: { color: "#002da1", fontSize: 10, fontFace: fontTahoma } },
            ],
            { x: 10.0, y: 4.0, w: 3, h: 3.3, valign: "top", align: "left", fill: "#edf6fe", lineSpacing: 10, fontFace: fontTahoma, fontSize: 10, margin: 15, isTextBox: true }
        );
        slide2.addText(
            [
                { text: "BUSINESS" },
                { text: "\nFINLAND", options: { bold: true } }
            ],
            { x: 0.25, y: 6.5, w: 3, h: 1.0, fontFace: fontTahoma, fontSize: 16, color: "#002ea2" }
        );
    });
}

 function renderPptxSlide3(pptx) {
    var slide3 = pptx.addSlide({ masterName: "PLACEHOLDER_SLIDE3", sectionTitle: "Contact information" });
    slide3.addText("BUSINESS FINLAND \nCONTACT PERSONS", { placeholder: "company_contact_header", fontFace: fontTahoma, fontSize: 20, color: "#002ea2" });
    slide3.addText(
        [
            {
                text: "\nwww.businessfinland.com", options: {
                    hyperlink: {
                        url: "https://www.businessfinland.com",
                        underline: true
                    }, color: "#002ea2", fontFace: fontTahoma
                }
            },
            { text: "\n\n\nJOB TITLE", options: { fontFace: fontTahoma } },
            { text: "\nFIRST NAME LAST NAME", options: { color: "#002ea2", fontFace: fontTahoma, bold: true } },
            { text: "\n+358000000000", options: { color: "#002ea2", fontFace: fontTahoma } },
            { text: "\nfirstname.lastname@businessfinland.fi", options: { color: "#002ea2", fontFace: fontTahoma } }
        ],
        { placeholder: "company_contact_info1" }
    );
    slide3.addText(
        [
            { text: "\n\n\n\n\nJOB TITLE", options: { fontFace: fontTahoma } },
            { text: "\nFIRST NAME LAST NAME", options: { color: "#002ea2", fontFace: fontTahoma, bold: true } },
            { text: "\n+358000000000", options: { color: "#002ea2", fontFace: fontTahoma } },
            { text: "\nfirstname.lastname@businessfinland.fi", options: { color: "#002ea2", fontFace: fontTahoma } }
        ],
        { placeholder: "company_contact_info2" }
     );
}

function renderPptxCompanyListSlide(companies, title, pptx) {
    var subOfferingTitlePpt = "";
    var companySlide = null;
    var xCoords = {
        counter: 0,
        logoX: 0.7,
        textX: 0.2
    };

    function subOfferingDetailsSlide(subofferingSlide, title, description) {
        subofferingSlide.addText(title, { placeholder: placeholderCompanyTitle });
        subofferingSlide.addText(description, { placeholder: placeholderCompanyTitleIntro });
    }

    function offeringTitle(companySlide, title) {
        companySlide.addText("", { x: 0, y: 0, w: "100%", h: 0.6, fill: "#002EA2", fontFace: fontTahoma, fontSize: 18, bold: true, color: "#ffffff" });
        companySlide.addText(title, { x: 0.3, y: 0, w: "97.5%", h: 0.6, fontFace: fontTahoma, fontSize: 18, bold: true, color: "#ffffff" });
    }

    // logox, textx and xCoords.counter can be zero which in JS equals to false. That is why there is no check for these values
    if (!companies || !pptx || !title) {
        return;
    }
     $.each(companies, function (index) {
         if (xCoords.counter === 0) {
             // new slide the same as the introduction slide but with sub offering details
             if (this.SubOfferingTitle && this.SubOfferingTitle !== subOfferingTitlePpt) {
                 var subofferingSlide = pptx.addSlide({ masterName: masterSlide1Name, sectionTitle: titleCompanies });
                 subOfferingDetailsSlide(subofferingSlide, this.SubOfferingTitle, this.SubOfferingDescription);
             }
             if (companySlide == null) {
                 companySlide = pptx.addSlide({ sectionTitle: titleCompanies });
             }
             if (this.SubOfferingTitle && this.SubOfferingTitle !== defaultPptxTitle) {
                 // title text in top of company list slide with blue background
                 offeringTitle(companySlide, this.SubOfferingTitle);
                 subOfferingTitlePpt = this.SubOfferingTitle;
             }
             else if (title && title !== defaultPptxTitle) {
                 if (companySlide == null) {
                     companySlide = pptx.addSlide({ sectionTitle: titleCompanies });
                 }
                 offeringTitle(companySlide, title);
             }
         }

         if (xCoords.counter > 0 && this.SubOfferingTitle && this.SubOfferingTitle !== subOfferingTitlePpt) {
             // Reset the counter because the suboffering should change here
             xCoords.counter = 0;
             xCoords.logoX = 0.7;
             xCoords.textX = 0.2;
             var subofferingSlide = pptx.addSlide({ masterName: masterSlide1Name, sectionTitle: titleCompanies });
             subOfferingDetailsSlide(subofferingSlide, this.SubOfferingTitle, this.SubOfferingDescription);
             companySlide = pptx.addSlide({ sectionTitle: titleCompanies });
             offeringTitle(companySlide, this.SubOfferingTitle);
             subOfferingTitlePpt = this.SubOfferingTitle;
         }

         if (xCoords.counter === 0 || xCoords.counter === 2) {
             companySlide.addText("", { x: xCoords.counter === 0 ? 0 : 6.6, y: 0.6, w: xCoords.counter === 0 ? 3.4 : 3.2, h: "92%", fill: "#edf6fe", fontFace: fontTahoma, fontSize: 18, bold: true, color: "#ffffff" })
         }
         if (this.Logo) {
             var logoHeight2 = 0.6;
             var logoWidth2 = 0.0;
             var aspectRatio5 = this.Logo.ImageAspectRatio;
             logoWidth2 = logoHeight2 * aspectRatio5;
             companySlide.addImage({ data: this.Logo.ImageBase64, x: xCoords.logoX, y: 0.8, h: logoHeight2, w: logoWidth2, sizing: { type: "contain", h: logoHeight2, w: 1.0 } });
         }
         companySlide.addText(this.Name, { x: xCoords.textX, y: 1.5, w: 3.2, h: 0.6, fontFace: fontTahoma, fontSize: 14, bold: true, color: "#002da1" });
         companySlide.addText(this.ShortIntroduction, { x: xCoords.textX, y: 2.1, w: 3.2, h: 2.0, align: 'justify', valign: "top", fontFace: fontTahoma, fontSize: 12, shrinkText: true });
         if (this.CompanyWebsite) {
             companySlide.addText(this.CompanyWebsite, { x: xCoords.textX, y: 4.4, w: 3.2, h: 0.3, hyperlink: { url: this.CompanyWebsite, underline: true }, color: "#002da1", fontFace: fontTahoma, fontSize: 12 });
         }
         if (this.CompanySize) {
             companySlide.addText("Company size", { x: xCoords.textX, y: 4.8, w: 3.2, h: 0.3, fontFace: fontTahoma, bold: true, fontSize: 12 });
             companySlide.addText(this.CompanySize, { x: xCoords.textX, y: 5.1, w: 3.2, h: 0.3, fontFace: fontTahoma, fontSize: 12 });
         }
         if (this.CompanyTurnover) {
             companySlide.addText("Turnover", { x: xCoords.textX, y: 5.4, w: 3.2, h: 0.3, fontFace: fontTahoma, bold: true, fontSize: 12 });
             companySlide.addText(this.CompanyTurnover, { x: xCoords.textX, y: 5.7, w: 3.2, h: 0.3, fontFace: fontTahoma, fontSize: 12 });
         }
         if (this.Keywords) {
             companySlide.addText("Keywords", { x: xCoords.textX, y: 6.1, w: 3.2, h: 0.3, fontFace: fontTahoma, bold: true, fontSize: 12 });
             companySlide.addText(this.Keywords, { x: xCoords.textX, y: 6.4, w: 3.2, h: 0.7, fontFace: fontTahoma, valign: "top", fontSize: 12 });
         }
         xCoords.logoX = xCoords.logoX + 3.2;
         xCoords.textX = xCoords.textX + 3.2;
         xCoords.counter = xCoords.counter + 1;
         if (xCoords.counter === 4) {
             xCoords.counter = 0;
             xCoords.logoX = 0.7;
             xCoords.textX = 0.2;
             companySlide = null;
         }
     });
}

/**
 * Get the company export url with url parameters.
 * @param {string} mode What export mode is used. Enum value from C# code. Check the ExportMode enum in C# for possible values.
 * @returns The company export url with url parameters.
 */
function getCompanyExportUrl(exporturl = null) {
    var ExportUrl = (companySearchOptions && companySearchOptions.exportUrl) || exporturl;
    if (ExportUrl) {
        var searchTerm = $('#company-search-query').val();
        var subOfferingLinkEl = document.getElementById('btn-explore');
        // Get suboffering value from html data attribute.
        var subOfferingDataValue = subOfferingLinkEl && subOfferingLinkEl.dataset && "s" in subOfferingLinkEl.dataset ? subOfferingLinkEl.dataset.s : "";
        var subofferingParam = new URLSearchParams(window.location.search).get("s") || subOfferingDataValue || (subOfferingLinkEl ? subOfferingLinkEl.getAttribute("data-s") : "");
        var idCompanyParam = new URLSearchParams(window.location.search).get("idCompany");
        var exportUrl = new URL(ExportUrl, document.location.origin);

        if (searchTerm) {
            exportUrl.searchParams.set("q", searchTerm);
        }

        if (subofferingParam) {
            exportUrl.searchParams.set("s", subofferingParam);
        }

        if (idCompanyParam) {
            exportUrl.searchParams.set("idCompany", idCompanyParam);
        }

        return exportUrl;
    }

    return "";
}

function GetMasterSlideIntroduction(pptx) {
    pptx.defineSlideMaster({
        title: masterSlide1Name,
        background: { color: "#002EA2" },
        objects: [
            { line: (pptx.shapes.LINE, { x: 3.0, y: 2.8, w: 0, h: 2.5, line: '#FFFFFF', line_size: 1 }) },
            { text: { text: "BUSINESS", options: { fontFace: fontTahoma, fontSize: 20, color: "#FFFFFF", x: 0.3, y: 6.0, w: 3, h: 1.0 } } },
            { text: { text: "\nFINLAND", options: { fontFace: fontTahoma, bold: true, fontSize: 20, color: "#FFFFFF", x: 0.3, y: 6.2, w: 3, h: 1.0 } } },
            {
                placeholder: {
                    options: { name: placeholderCompanyTitle, type: "body", x: 0.3, y: 3.0, w: "2.5", h: 2.0, color: "#FFFFFF", fontFace: fontTahoma, fontSize: 20 },
                    text: "(" + placeholderCompanyTitle + ")",
                },
            },
            {
                placeholder: {
                    options: { name: placeholderCompanyTitleIntro, type: "body", x: 3.5, y: 3.0, w: "8", h: 2.0, color: "#FFFFFF", fontFace: fontTahoma, fontSize: 16, align: "justify" },
                    text: "(" + placeholderCompanyTitleIntro + ")",
                },
            },
        ],
    });
}

function GetMasterSlideContactInformation(pptx) {
    pptx.defineSlideMaster({
        title: "PLACEHOLDER_SLIDE3",
        objects: [
            { text: { text: "BUSINESS", options: { fontFace: fontTahoma, fontSize: 20, color: "#002ea2", x: 0.3, y: 6.0, w: 3, h: 1.0 } } },
            { text: { text: "\nFINLAND", options: { bold: true, fontFace: fontTahoma, fontSize: 20, color: "#002ea2", x: 0.3, y: 6.2, w: 3, h: 1.0 } } },
            {

                placeholder: {
                    options: { name: "company_contact_header", type: "body", x: 0.3, y: 2.2, h: 1.0, w: "4", fontFace: fontTahoma, fontSize: 20, align: "left" },
                    text: "(company_contact_header)",
                },
            },
            {
                placeholder: {
                    options: { name: "company_contact_info1", type: "body", x: 0.3, y: 3.4, h: 2.5, w: "4", fontFace: fontTahoma, fontSize: 10, fill: "#E0E0E0", lineSpacing: 5 },
                    text: "(company_contact_info1)",
                },
            },
            {
                placeholder: {
                    options: { name: "company_contact_info2", type: "body", x: 4.3, y: 3.4, h: 2.5, w: "4", fontFace: fontTahoma, fontSize: 10, fill: "#E0E0E0", lineSpacing: 5 },
                    text: "(company_contact_info2)",
                },
            },
        ],
    });
}

export var exportJs = {
     BtnExport: function () {
        $("div.main-for-export").hide();
        $("div.export").show();
        $("ol.breadcrumbs").hide();
    },

    BtnBack: function () {
        // Let's check in which view the user is in
        var backButton = $(".export-back");
        if (backButton.length) {
            var view = backButton.data("currentView");
            if (view === "main") {
                // We were in main view of the export so let's show the shortlist/collection page data
                $("div.export").hide();
                $("div.main-for-export").show();
                $("ol.breadcrumbs").show();
            }
            else {
                // We were in sub view (copy/email link) so we need to show the main view of the export
                $("div.export-main").show();
                $("div.export-copy").hide();
                $("div.export-email").hide();
                // Update the back link text and data
                backButton.data("currentView", "main");
                backButton.text(backButton.data("back"));
            }
        }
    },

    BtnCopy: function () {
        $("div.export-main").hide();
        $("div.export-copy").show();
        // Let's update the back button text and data -> we are now on sub view
        var backButton = $(".export-back");
        if (backButton) {
            backButton.data("currentView", "copy");
            backButton.text(backButton.data("backExport"));
        }
    },

    BtnEmailView: function () {
        $("div.export-main").hide();
        $("div.export-email").show();
        // Let's update the back button text and data
        var backButton = $(".export-back");
        if (backButton) {
            backButton.data("currentView", "email");
            backButton.text(backButton.data("backExport"));
        }
    },

    BtnDownloadPptx: function (exporturl = null) {
        if (companySearchOptions.exportUrl || exporturl) {
            var spinner = $(".form-element__spinner");
            $.ajax({
                url: getCompanyExportUrl(exporturl),
                type: 'POST',
                beforeSend: function () {
                    // Disable buttons
                    $("button.export").prop('disabled', true);
                    $("button.export").addClass('disabled');
                    // Show the spinner
                    if (spinner) {
                        spinner.show();
                    }
                }
            })
            .done(function (data) {
                if (data.Companies.length > 0) {
                    var pptx = new pptxgen();
                    pptx.author = 'Business Finland';
                    pptx.company = 'Business Finland';
                    pptx.subject = titleCompanies;
                    pptx.title = titleCompanies;
                    pptx.layout = "LAYOUT_WIDE";
                    GetMasterSlideIntroduction(pptx);
                    GetMasterSlideContactInformation(pptx);
                    pptx.addSection({ title: sectionTitleIntroduction });
                    pptx.addSection({ title: titleCompanies });
                    pptx.addSection({ title: "Contact information" });

                    renderPptxSlide1(pptx, data);
                    renderPptxSlide2(pptx, data.Companies);
                    renderPptxSlide3(pptx);
                    pptx.writeFile()
                        .then(() => {
                            downloadSuccessfull($(".success-download"), spinner);
                        });
                }
                else {
                    downloadError($(".danger-download"), spinner);
                }
            })
            .fail(function (error) {
                console.error('Error:', error);
                downloadError($(".danger-download"), spinner);
            });
        }
    },

    BtnDownloadPptxList: function (exporturl = null) {
        if (companySearchOptions.exportUrl || exporturl) {
            var spinner = $(".form-element__spinner");
            $.ajax({
                url: getCompanyExportUrl(exporturl),
                type: 'POST',
                beforeSend: function () {
                    // Disable buttons
                    $("button.export").prop('disabled', true);
                    $("button.export").addClass('disabled');
                    // Show the spinner
                    if (spinner) {
                        spinner.show();
                    }
                }
            })
            .done(function (data) {
                if (data.Companies.length > 0) {
                    var pptx = new pptxgen();
                    pptx.author = 'Business Finland';
                    pptx.company = 'Business Finland';
                    pptx.subject = titleCompanies;
                    pptx.title = titleCompanies;
                    pptx.layout = "LAYOUT_WIDE";
                    GetMasterSlideIntroduction(pptx);
                    GetMasterSlideContactInformation(pptx);
                    pptx.addSection({ title: sectionTitleIntroduction });
                    pptx.addSection({ title: titleCompanies });
                    pptx.addSection({ title: "Contact information" });

                    renderPptxSlide1(pptx, data);
                    renderPptxCompanyListSlide(data.Companies, data.Title, pptx);
                    renderPptxSlide3(pptx);
                    pptx.writeFile()
                        .then(() => {
                            downloadSuccessfull($(".success-download"), spinner);
                        });
                }
                else {
                    downloadError($(".danger-download"), spinner);
                }
            })
            .fail(function (error) {
                console.error('Error:', error);
                downloadError($(".danger-download"), spinner);
            });
        }
    },

    BtnEmail: function () {
        var form = $('#jsSendEmail');
        if (form.length) {
            form.parsley().validate();
            if (form.parsley().isValid()) {
                if (companySearchOptions.emailUrl) {
                    $('.js-email-link').prop('disabled', true);
                    $('.js-email-link').addClass('disabled');
                    var dataForm = new FormData(form[0]);
                    dataForm.append('emailAddress', $('#export-email-address').val())
                    dataForm.append('msg', $('#export-email-message').val())
                    $.ajax({
                        url: companySearchOptions.emailUrl,
                        type: 'POST',
                        enctype: 'multipart/form-data',
                        contentType: false,
                        data: dataForm,
                        processData: false,
                    })
                        .done(function () {
                            $('.email-send-success').show();
                        })
                        .fail(function () {
                            $('.email-send-fail').show();
                        });
                }
            }
        }
        return false;
    }
}